import React from 'react';
//import { useState, useParams } from "react";
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import { DarkTheme } from './styled/themes';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    gql
  } from "@apollo/client";
//import { onError } from '@apollo/client/link/error';

import Home from './pages/App';
import TOS from './pages/TOS';
import Privacy from './pages/Privacy';
import Search from './pages/Search';
import Dashboard from './pages/Dashboard';
import LinkedRoles from './pages/LinkedRoles';
import Xbox from './pages/Xbox';

const client = new ApolloClient({
    cache: new InMemoryCache(),
    uri: 'http://localhost:3001/api'
});
function MicrosoftAuth() {
    return {
        "associatedApplications": [
        {
          "applicationId": "20414e1a-9051-4787-aa21-cc6ef93a51ad"
        }
    ]};
}

ReactDOM.render(
    <Router>
        {/* <Routes>
            <Route path="/.well-known/microsoft-identify-association.json">
                <Navigate push to={"/.well-known/microsoft-identify-association.json"} />
            </Route>
        </Routes> */}
        <ThemeProvider theme={DarkTheme}>
            <ApolloProvider client={client}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/tos" element={<TOS />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/search" element={<Search />} />
                    <Route path="/search" element={<Dashboard />} />
                    <Route path="/dashboard/:id" element={<Dashboard />}/>
                    <Route path="/xbox/:xuid" element={<Xbox />}/>
                    <Route path="/linked-roles" element={<LinkedRoles />} />
                </Routes>
            </ApolloProvider>
        </ThemeProvider>
    </Router>,
    document.getElementById('root')
);