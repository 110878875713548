const { gql } = require('@apollo/client');

export const LOAD_USERS = gql`
    query {
        users {
            duid
            xuid
            discordTag
            modernGamertag
            gamertag
            socialclub
        }
    }
`;