import React from 'react'
import { useHistory, useParams } from 'react-router-dom'


import { Footer, Features, Header } from '../containers';
import { Brand, Navbar, Sidebar } from '../components';
import { SidebarStyle, SidebarHeader, SidebarContent } from '../styled/styles';

import '../App.css';
import '../index.css';

const Dashboard = () => {
  const { id } = useParams()
  return (
    <div className='App'>
      <div className='gradient__bg'>
          <Navbar />
      </div>
      <div>
        {id}
      </div>
      <Footer />
    </div>
  )
}

export default Dashboard