import React, { useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import { LOAD_USERS } from '../../graphql/queries';

const GetUsers = ({search}) => {
  const { error, loading, data } = useQuery(LOAD_USERS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const filtered = data.users.filter((val) => {
      if(search === '') {
          return val;
      }
      else if(val.gamertag !== null) {
          if(val.gamertag.toLowerCase().includes(search.toLowerCase())) {
              return val;
          }
      }
  });
  return filtered.map(({ duid, gamertag }) => (
    <div key={duid}>
    <p>
        {gamertag}
    </p>
    </div>
  ));
}

export default GetUsers