import React from 'react'

import { Footer, Features, Header } from '../containers';
import { Brand, Navbar } from '../components';

import '../App.css';
import '../index.css';

const App = () => {
  return (
    <div className='App'>
      <div className='gradient__bg'>
          <Navbar />
          <Header />
      </div>
      <Brand />
      <Features />
      <Footer />
    </div>
  )
}

export default App