import React from "react";

import { Footer, Section } from '../containers';
import { Navbar } from '../components';

import '../App.css';
import '../index.css';

function Privacy() {
    return(
        <div className='Privacy'>
            <div className='gradient__bg'>
                <Navbar />
            </div>
            <Section title='Privacy Policy' text='View the Privacy Policy for the MILSTAR Discord Bot below.'/>
            <div className="milstar__termsofservice section__padding">
                <h1>PRIVACY NOTICE</h1>
                <h2>Last updated August 15, 2022</h2>
                <div />
                <p>This privacy notice for MILSTAR ("we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:</p>
                <p>Use our application (MILSTAR Discord Bot), or any other application of ours that links to this privacy notice</p>
                <p>Questions or concerns? If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at zero@milstar.info.</p>
                <div />
                <h1>1. WHAT INFORMATION DO WE COLLECT?</h1>
                <p>We do not collect or store any personal information. Any information we do collect, is collected only for the purposes of providing the services of MILSTAR to you.</p>
                <div />
                <h1>2. CONTACT US</h1>
                <p>If you have questions or comments about this notice, you may email us at zero@milstar.info</p>
                <p>zero@milstar.info</p>
            </div>
            <Footer />
        </div>
    )
}

export default Privacy