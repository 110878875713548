import React from 'react'
import './header.css';
import milstarLogo from '../../assets/milstar.png';
import bgImage from '../../assets/header_bg.png';



const Header = () => {
  return (
    <div className='milstar__header section__padding' id='home' style={
      {
        backgroundImage: `url(${bgImage})`,
        backgroundAttachment: `fixed`,
        backgroundPosition: `90% 0%`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `auto 100%`
      }
    }>
      <div className='milstar__header-content'>
        <h1 className='gradient__text'>
          MILSTAR stands for Military Strategic and Tactical Relay, built for members of the GTA Community.
        </h1>
        <p>
          Access to your favorite services from the convenience of Discord. View GTA Online stats, Rockstar Social Club profiles, in game character Mugshots, and Xbox Playercards. Compare with other players, and use fun commands to bring the chaotic antics of GTA Online into your Discord.
        </p>
      </div>
      <div className='milstar__header-content'>
        <img src={milstarLogo} alt='MILSTAR Logo'/>
      </div>
    </div>
  )
}

export default Header