import React from 'react';
import { useState } from "react";

import { Navbar } from '../components';

import { FormItemContainer, FormTextItem, Button } from '../styled/styles';

import '../App.css';
import '../index.css';
import GetUsers from '../components/api/GetUsers';



const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  return (
    <div className='Search'>
      <div className='gradient__bg'>
          <Navbar />
      </div>
      <FormItemContainer>
        <label>Search</label>
        <FormTextItem
          onChange={(e) => {
            /* Handle updating search/filter text */
            setSearchTerm(e.target.value);
          }}
          type="string" placeholder='Gamertag'
        />
        <Button onClick={() => {
          /* Handle search/filter */
          this.setState({ search: true })

        }}>Submit</Button>
      </FormItemContainer>
      <div className='milstar__starid-users section__padding'>
        <GetUsers search={searchTerm} />
      </div>
    </div>
  )
}

export default Search