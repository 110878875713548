import React from "react";

import { Footer, Section } from '../containers';
import { Navbar, Feature } from '../components';

import '../App.css';
import '../index.css';

function LinkedRoles() {
    return(
        <div className='Privacy'>
            <div className='gradient__bg'>
                <Navbar />
            </div>
            <LinkedRoles />
            <Footer />
        </div>
    )
}

export default LinkedRoles