import React, { useState } from 'react'
import {RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/milstar.png';
import './navbar.css';
import {
  Link
} from 'react-router-dom';
import styled from "styled-components";
import { NavbarButton, MenuCategoryItem } from '../../styled/styles';

const NavbarItem = styled(Link)`
    font-size: 20px;
    font-weight: bold;
    transition: 100ms background-color;
    :hover {
        cursor: pointer;
        color: #EE7600 !important;
        text-shadow: 0px 0px 7px rgba(243, 159, 76, .6);
    }
`;


const Menu = () => (
  <>
  <p><NavbarItem to='/'>Home</NavbarItem></p>
  <p><MenuCategoryItem href='/#features'>Features</MenuCategoryItem></p>
  <p><NavbarItem to='/tos'>Terms of Service</NavbarItem></p>
  <p><NavbarItem to='/privacy'>Privacy</NavbarItem></p>
  {/* <p><NavbarItem to='/search'>Xbox Live</NavbarItem></p> */}
  </>
)

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className='milstar__navbar'>
      <div className='milstar__navbar-links'>
        <div className='milstar__navbar-links_logo'>
          <img src={logo} alt='logo' />
        </div>
        <div className='milstar__navbar-links_container'>
          <Menu />
        </div>
      </div>
      <div className='milstar__navbar-sign'>
        <p>Sign In</p>
        <NavbarButton href='https://discord.com/oauth2/authorize?client_id=515671463081017344&permissions=4228906239&scope=bot+applications.commands&response_type=code'>Add to Discord</NavbarButton>
      </div>
      <div className='milstar__navbar-menu'>
        {toggleMenu
          ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />
        }
        {toggleMenu && (
          <div className='milstar__navbar-menu_container scale-up-tr'>
            <div className='milstar__navbar-menu_contrainer-links'>
              <Menu />
              <div className='milstar__navbar-menu_container-links-sign'>
                <p>Sign In</p>
                <NavbarButton href='https://discord.com/oauth2/authorize?client_id=515671463081017344&permissions=4228906239&scope=bot+applications.commands&response_type=code'>Add to Discord</NavbarButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar