
export const DarkTheme = {
    base: {
        sidebar: "#1a1a1a",
        menu: "#2F3136",
        content: "#36393F",
        alt: "#EAEAEA",
    },
    text: {
        main: "#FFFFFF",
        alt: "#050404",
    },
    height: {
        topContent: "95px",
    },
    padding: {
        content: "20px 0px"
    },
};

export const LightTheme = {
    base: {
        sidebar: "#FFFFFF",
        menu: "#2F3136",
        content: "#36393F",
        alt: "#EAEAEA",
    },
    text: {
        main: "#FFFFFF",
        alt: "#050404",
    },
    height: {
        topContent: "95px",
    },
    padding: {
        content: "20px 0px"
    },
};