import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

import gtaxbox from '../../assets/gtaxbox.png';
import starid from '../../assets/starid.png';
import socialclubstats from '../../assets/socialclubstats.png';

const Features = () => {
  return (
    <div className='milstar__features section__margin' id='features'>
      <div className='milstar__features-content section__padding'>
        <h1 className='gradient__text'>
          Deep Integration
        </h1>
        <p>
          Deeply integrate your community with GTA Online and Xbox Live. View your GTA Online stats, Rockstar Social Club profiles, in game character Mugshots, and Xbox Playercards. Compare with other players, and use fun commands to bring the chaotic antics of GTA Online into your Discord (in a controlled fashion of course).
        </p>
      </div>
      <div className='milstar__feature-container section__padding'>
        <div className='milstar__feature-container__feature'>
          <img src={gtaxbox} alt="feature" />
          <Feature title='GTA Online and Xbox Live' text='View GTA Online character Mugshots, and generate an assortment of feed tickers using fun commands. Fetch Xbox Live Playercards with ease.' />
        </div>
        <div className='milstar__feature-container__feature'>
          <img src={starid} alt="feature" />
          <Feature title='STARID' text='Link your Gamertag and register a STARID to get access to a custom GTA Online stats card with a breakdown of player attributes, Cash/Bank balance, and spending. As well as access to MILNET Crew features.'/>  
        </div>
        <div className='milstar__feature-container__feature'>
          <img src={socialclubstats} alt="feature" />
          <Feature title='Rockstar Social Club' text='Exclusive access to Rockstar Games Social Club through Discord. No need to launch a web browser to view in depth stats.'/>  
        </div>
      </div>
      <div className='section__padding' />
    </div>
  )
}

export default Features