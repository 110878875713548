import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <div className='milstar__footer section__padding'>
        <div className='milstar__footer-text'>
          © MILSTAR 2022
        </div>
        <div className='milstar__footer-text'>
          MILSTAR is not affiliated with Rockstar Games®, Microsoft® or Xbox®. All names and related logos are registered trademarks of their respective owners.
        </div>
    </div>
  )
}

export default Footer