import React from 'react'
import './section.css';

const Section = ({title, text, heading, imports}) => {
  return (
    <div className='milstar__section section__padding' id='home'>
      <div className='milstar__section-content'>
        <h1 className='gradient__text'>
          {title}
        </h1>
        <p>
          {text}          
        </p>
      </div>
      <div className='milstar__section-content'>
        <h2>
          {heading}
        </h2>
        {imports}
      </div>
    </div>
  )
}

export default Section