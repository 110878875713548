import React from 'react';
import { discord, xbox, rockstargames, gtaonline } from './imports';
import './brand.css';

const Brand = () => {
  return (
    <div className='milstar__brand section__margin'>
        <div>
          <img src={discord} alt='Discord'/>
        </div>
        <div>
          <img src={rockstargames} alt='Rockstar Games'/>
        </div>
        <div>
          <img src={gtaonline} alt='GTA Online'/>
        </div>
        <div>
          <img src={xbox} alt='Xbox'/>
        </div>
    </div>
  )
}

export default Brand